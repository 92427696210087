.chart {
  margin: 6px 6px 20px;
  background-color: white;
  /* width: fit-content; */
  height: fit-content;
  padding: 12px 12px 18px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 6px 0px #00000029;
  font-family: Inter;
  width: 100%;
  max-width: 800px;
}
