.powerCardSpace {
  width: 100%;
  min-height: 400px;
  border-radius: 0.5rem;
  background: var(--White, #fff);
  box-shadow: 0px 0px 6px 0px #00000029;
  margin: 12px 0 0 0;
  overflow: hidden;

  .lazyContainer {
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;

    .powerCard {
      width: 100%;
      /* min-height      : 400px; */
      min-height: inherit;
      max-height: inherit;
      /* Shadow – Map Comps */

      &.fullScreen {
        max-height: none;

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        overflow-y: scroll;
        overflow-x: hidden;

        .container {
          .content {
            overscroll-behavior-y: contain;
          }
        }
      }

      .container {
        display: flex;
        flex-direction: column;
        min-height: inherit;
        max-height: inherit;
        height: 100%;
        /* background-color: green; */

        .header {
          display: flex;
          justify-content: space-between;
          margin-top: 6px;
          margin-right: 6px;
          height: 36px;

          .titleContainer {
            /* border-bottom: 2px solid #000; */
            padding-right: 8px;
            display: flex;
            align-items: center;

            .infoContainer {
              min-width: 12px;
              height: 100%;

              .info {
                width: 30px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                color: #000;
                cursor: pointer;
              }
            }

            .title {
              text-align: center;
              color: var(--power-dev-black-040000, #040000);
              font-family: Inter;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }

          .toolboxContainer {
            gap: 4px;
            display: flex;
          }
        }

        .content {
          padding: 0 12px;
          margin: 12px 0;
          /* background-color: blue; */
          min-height: inherit;
          max-height: inherit;
          height: 100%;
          overflow: auto;
        }
      }
    }
  }
}
