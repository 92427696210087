.average {
  color: var(--text-dark-grey-665-b-5-b, #665b5b);
  text-align: right;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.widget {
  width: 100%;
  height: 100%;

  .yAxis {
    margin-bottom: -1px;
  }

  .xAxis {
    text-align: end;
    margin-top: -18px;
  }
}
