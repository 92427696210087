@import '_themes-vars.module.scss';

.page-container {
  position: relative;
  width: 100%;
  .page-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.page-content {
  padding-left: 16px;
  padding-right: 16px;
}
.page-tab-container {
  margin-top: 16px;
  .ant-tabs {
    .ant-tabs-nav-wrap {
      width: 100%;
      display: block;
      padding-left: 10px;
    }
    &-nav {
      display: flex;

      .ant-tabs-tab {
        flex-grow: 1;
        margin-right: 10px;
        width: 100%;
        text-align: center;
        border-color: $baseBorderColor;
      }
    }
    .ant-tabs-nav {
      &::before {
        border-bottom: 2px solid $baseBorderColor;
      }
    }
  }
}

.page-status-loading {
  /* position: fixed;
  width: unset;
  left: 259px;
  right: 16px; */
}

.pl-status-code {
  padding-left: 20px;
}

.page-container-blocker {
  position: fixed;
  text-align: center;
  top: 62px;
  bottom: 0;
  left: 48px; // 241px;
  right: 46px;
  /* width: 100%; */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 4;

  transition: ease 0.4s;

  .panel-loader {
    margin: auto;
    top: 50%;
    margin-top: -70px;
  }

  .page-container-blocker-message {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 2.5em;
    font-weight: 500;
    letter-spacing: 2px;
  }
}

.panel-loader-error {
  position: relative;
  background-color: $cardBg;
  text-align: center;
  padding-top: 25vh;
  width: 100%;
  height: 100%;
  min-height: 94vh;
  z-index: 1;

  .pl-error-icon {
    margin: auto;
    margin-top: -60px;
    font-size: 90px;
    color: $error500;
  }

  .panel-loader-blocker-message {
    margin-top: 60px;
    width: 100%;
    text-align: center;
    color: $black;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 2.8em;
    padding-left: 30%;
    padding-right: 30%;
    line-height: 1.1em;
  }

  .cp-refresh-button {
    display: block;
    text-align: center;
    padding-top: 15px;
  }
}

.panel-loader-no-data {
  text-align: center;
  padding-top: 25vh;
  width: 100%;
  height: 100%;
  min-height: 94vh;
  background-color: $cardBg;
  z-index: 1;

  .pl-no-data-icon {
    margin: auto;
    margin-top: -85px;
    font-size: 90px;
  }

  .panel-loader-blocker-message {
    margin-top: 60px;
    width: 100%;
    text-align: center;
    color: $black;
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 2.8em;
    padding-left: 30%;
    padding-right: 30%;
    line-height: 1.1em;
  }

  .pl-refresh-button {
    display: block;
    text-align: center;
    padding-top: 15px;
  }
}

.sidebar-collapsed {
  .page-container-blocker {
    left: 48px;
  }

  .page-status-loading {
    left: 48px;
  }
}

@media screen and (max-width: 1024px) {
  .page-container {
    &.page-status-loading {
      left: 17.5px;
      right: 17.5px;
    }
  }

  .sidebar-collapsed {
    .page-container {
      &.page-status-loading {
        left: 17.5px;
        right: 17.5px;
      }
    }
  }
}
