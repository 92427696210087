@import '_themes-vars.module.scss';

.filter-bar-container {
  position: relative;
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
}

.filter-bar-row {
  border-bottom: 1px solid #c9c1c1;
  padding: 0 16px;
  position: relative;
}

.filter-bar-col {
  position: relative;

  > .filter-bar-cell {
    border-right: 1px solid #c9c1c1;
    padding: 16px;
    display: inline-block;

    > a,
    > span,
    > button {
      color: #040000;
      font-size: 14px;
      padding: 0;
      display: inline-block;
      border: none;
      line-height: 1em;
      background-color: transparent;

      &:hover {
        color: #449c8c;
        background-color: transparent;
      }
    }

    > button {
      box-shadow: none;
      height: auto;
    }

    > span.disabled {
      color: #707070;
    }

    &:hover {
      background-color: #f4f2f2;
    }
  }

  > .ant-row > .ant-col {
    border-right: 1px solid #c9c1c1;
    padding-left: 10px;
    padding-bottom: 2px;
    padding-right: 10px;
    padding-top: 2px;
  }

  > .ant-row > .ant-col,
  > .filter-bar-cell {
    .ant-form-item-label {
      font-size: 11px;
      line-height: 15px;
      padding: 0;

      label {
        color: inherit;
        font-size: inherit;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-select-selector {
      border-width: 0;
    }

    .ant-select-focused .ant-select-selector {
      border-color: transparent !important;
      outline: 0;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    .btn-filter-bar-green {
      width: 100%;
      position: relative;
      height: 100%;
      /* border-width    : 0;
      background-color   : $success500;
      font-weight        : 500; */
      color: #fff !important;
      border-radius: 0;

      &[disabled] {
        background-color: $grey400;
      }

      &:hover,
      &:active {
        &:not([disabled]) {
          background-color: $success300;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .filter-bar-container.filter-bar-map {
    padding: 0;
    background-color: #f4f2f2;

    .filter-bar-col {
      border-width: 0;
      padding: 16px 0px;
    }
  }

  .filter-bar-container.filter-bar-mobile {
    padding: 15px;
    background-color: $cardBgGreen;

    .filter-bar-row {
      padding-right: 0;
      border: none;
    }

    .filter-bar-col {
      border-width: 0;
      border: none;
    }
  }

  .filter-bar-col {
    border-width: 0;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .filter-bar-col {
    width: 100%;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .filter-bar-col {
    width: 90%;
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .filter-bar-col {
    width: 80%;
  }
}

@media screen and (min-width: $breakpoint-xxl) {
  .filter-bar-col {
    width: 65%;
  }
}
