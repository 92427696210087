.nodeLayerControl {
  max-height: 190px;
  
  .radioGroup {
    width: 100%;
    /* padding-top: 5px; */
    border-bottom: 1px solid #42b38922;
  
    :global(.ant-radio-button-wrapper:before) {
      background-color: transparent !important;
    }
  
    :global(.ant-radio-group-solid .ant-radio-button-wrapper-checked) {
      background-color: white !important;
      color: #42b389 !important;
      font-weight: 600 !important;
    }
  
    :global(.ant-radio-group-solid .ant-radio-button-wrapper:hover) {
      background-color: #f0fff7 !important;
    }
  }

  .queryBase{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 6px;
  }

  .legendControl {
    padding: 4px 10px;
    box-shadow: 0px 0px 6px 0px #00000029;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-top: 4px;
  }
}




