.sider {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  :global(.ant-radio-button-wrapper) {
    padding-inline: 10px !important;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}
