

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px;
  
  .title{
    font-weight: 600;
    color: #42b389;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .datas{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */

    .data{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label{
        font-weight: 300;
        color: #665b5b;
        display: inline-block;
        padding-right: 10px;
      }

      .value{
        font-weight: 600;
        color: #665b5b;
        display: inline-block;
      }
    }
  }
}
