html,
body,
#root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  height: 100%;
}

/* MAPBOX */
.mapboxgl-ctrl-attrib {
  display: none !important;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-ctrl-top-center {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 50%;
  translate: -50%;
  margin-top: 10px;
}
.mapboxgl-ctrl-bottom-center {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 50%;
  translate: -50%;
  margin-bottom: 10px;
}
/* MAPBOX */

/** UU */
.power-map-popup-container {
  max-width: 800px;
  max-height: 800px;
  min-width: 400px;
  min-height: 400px;
  overflow: scroll;
}
.powermap-custom-control-panel {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px #00000029;
  padding: 12px 24px;
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}
#sampleMap canvas {
  cursor: crosshair;
}
.mapboxgl-popup-close-button {
  width: 32px;
  height: 32px;
  font-size: 24px;
}
.mapboxgl-popup-content {
  box-shadow: 0px 0px 6px 0px #00000029;
}
