.chart {
  position: relative;
  overflow: hidden;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 76px;
  background-color: white;
  /* width: fit-content; */
  height: fit-content;
  padding: 14px 24px 20px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 6px 0px #00000029;
  font-family: Inter;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
