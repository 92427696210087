.mainLogo {
  cursor: pointer;
  background-color: transparent;
  border-bottom: none;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0;
  img {
    height: 100%;
  }
}
