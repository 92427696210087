.infoBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 160px;

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px;
    height: 100%;
    color: #040000;

    .title1 {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
    }

    .identifier {
      font-family: Inter;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      text-align: left;
    }

    .title2 {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: left;
    }

    .name {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: left;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    height: 100%;
    color: #665b5b;

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title1 {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      text-align: center;
    }

    .voltage {
      font-family: Inter;
      font-size: 24px;
      font-weight: 600;
      line-height: 29.05px;
      text-align: center;
      color: #42b389;
    }
    .unit {
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      text-align: center;
      color: #42b389;
    }
    .icon {
      width: 32px;
      height: 32px;
    }
    .transformer {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      text-align: center;
    }
  }
}
