.info {
  border-radius: 0.5rem;
  background: var(--White, #fff);
  /* Shadow – Map Comps */
  box-shadow: 0px 0px 6px 0px #00000029;
  margin: 12px 0px 0px 12px;
  padding: 6px 16px 12px;
  min-width: 250px;
  width: fit-content;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .title {
      color: #665b5b;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .subtitle {
      color: #597ef7;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .close {
      cursor: pointer;
      color: #665b5b;
      font-size: 1.25rem;
      margin-right: -9px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    .rowTitles {
      display: flex;
      flex-direction: column;
      gap: 2px;

      color: #665b5b;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.125rem; /* 150% */
    }

    .rowValuesPast {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 2px;
      padding-left: 16px;
      border-left: 1px solid #c9c1c1;

      color: #807272;
      text-align: right;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.125rem; /* 150% */

      .pastGreen{
        color: #42B389;
      }
    }

    .rowValuesCurrent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 2px;
      padding-left: 16px;
      border-left: 1px solid #c9c1c1;

      color: #42B389;
      text-align: right;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.125rem; /* 150% */
    }

    .rowValues {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 2px;
      padding-left: 16px;
      border-left: 1px solid #c9c1c1;

      color: #040000;
      /* color: #42B389; */
      text-align: right;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.125rem; /* 150% */
    }
  }
}
