.content {
  width: 100%;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./../../../assets/images/login-bg-tiny.jpeg');
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;

  .form {
    width: 350px;
    min-height: 350px;
    padding: 2rem 2rem 0;
    background-color: #fff;
    border-radius: 8px;

    .logo {
      background-image: url('./../../../assets/svg/logo.svg');
      background-size: contain;
      margin: 1rem 25% 3rem;
      width: 50%;
      height: 100px;
      background-repeat: no-repeat;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0 0 1.5rem;
      text-align: center;
    }

    .subtitle {
      /* font-size  : 1rem;
      font-weight: 400; */
      margin: 0 0 1.5rem;
      text-align: center;
    }
  }
}
