// color variants
@import '_themes-vars.module.scss';

// ==============================|| VECTOR ||============================== //
@mixin vector_fill($color, $hover-color) {
  fill: $color !important;
}

.vector-white {
  @include vector_fill($white, null);
}

.vector-black {
  @include vector_fill($black, $secondary-color);
}

.vector-black.hover {
  fill: $secondary500 !important;
}

.vector-primary {
  @include vector_fill($primary-color, $primary500);
}

.vector-primary.hover {
  fill: $primary500 !important;
}

.vector-success {
  @include vector_fill($green-base, $green500);
}

.vector-success.hover {
  fill: $green500 !important;
}

.vector-error {
  @include vector_fill($error-base, $error500);
}

.vector-error.hover {
  fill: $error500 !important;
}

// ==============================|| BEGIN:CARD ||============================== //

.summary-table.chart {
  height: 260px;
}

.card {
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-height: fit-content;
  background-color: $cardBg;
  padding: 16px 32px;
  margin-bottom: 16px;

  .ant-form-item {
    margin-bottom: 0;
    max-width: 130px;
  }

  .MuiBackdrop-root {
    top: 10px;
  }
}

.chart,
.antd-chart {
  height: 400px !important;
  width: 100%;
  position: relative;
}

.chart-long {
  height: 500px !important;
  width: 100%;
  position: relative;
}

.chart-sm {
  height: 315px !important;
  width: 100%;
  position: relative;
}

.fullscreen .card {
  min-height: 400px;

  .chart,
  .antd-chart,
  .chart-long,
  .heatmap-chart {
    width: 100% !important;
  }
}

.fullscreen-enabled .card {
  background-color: $cardBg;
  min-width: 100%;
  min-height: 80% !important;
  overflow-y: auto;

  .chart {
    height: 72vh !important;
    width: 100% !important;
  }

  .antd-chart,
  .chart-long {
    height: 90vh !important;
    width: 100% !important;
  }

  .heatmap-chart {
    height: 74vh !important;
    width: 100% !important;
  }
}

.fullscreen-enabled {
  .card-panel-content {
    background-color: $cardBg;
    min-width: 100%;
    min-height: 80% !important;
    overflow-y: auto;

    .chart {
      height: 72vh !important;
      width: 100% !important;
    }

    .antd-chart {
      height: 84vh !important;
      width: 100% !important;
    }

    .chart-long {
      height: 90vh !important;
      width: 100% !important;
    }

    .heatmap-chart {
      height: 74vh !important;
      width: 100% !important;
    }
  }
}

.chart-label-wrapper-left {
  width: 100%;
  padding-left: 30px;
  position: relative;
}

.chart-label-wrapper-right {
  width: 100%;
  padding-right: 30px;
  position: relative;
}

.chart-label {
  font-size: 12px;
  line-height: 16px;
  color: #040000;
  font-weight: 500;
  white-space: nowrap;
}

.chart-label-vertical {
  position: absolute;
  top: 45%;
  font-size: 12px;
  line-height: 16px;
  color: #040000;
  font-weight: 500;
  white-space: nowrap;
}

.chart-label-vertical-left {
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left bottom 0;
  left: 15px;
}

.chart-label-vertical-right {
  transform: rotate(90deg) translateX(50%);
  transform-origin: right bottom;
  right: 15px;
}

.card-content {
  padding: 16px 0;
  position: relative;
  min-height: 300px;
}

.card-header {
  height: 44px;
  position: relative;
  color: #040000;

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: #040000;
    position: absolute;
    left: -32px;
    border-bottom: 1px solid #040000;
    padding-left: 32px;
    line-height: 44px;
    bottom: 0;
    padding-right: 16px;
  }

  .card-header-arrow {
    position: absolute;
    height: 1px;
    border-bottom: 2px solid #040000;
    left: -16px;
    bottom: 0;
    width: 30%;
    min-width: 300px;
    max-width: 400px;
    display: none;
  }

  .card-header-toolbar {
    position: absolute;
    right: 16px;
    top: 0;

    > * {
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.card-overview {
  .card-header h3 {
    background-color: #040000;
    color: #fff;
    top: -16px;
    bottom: 16px;
  }

  .card-content {
    .card-header h3 {
      background-color: transparent;
      color: #040000;
    }
  }
}

// chart tooltip styles

.overlay {
  fill: none;
  pointer-events: all;
}

.tooltip {
  fill: white;
}

.tooltip-content {
  font-size: 12px;
}

.tooltip-title {
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;

  font-size: 10px;
  line-height: 16px;

  /* Neutral/Main-black */
  color: #040000;

  background-color: #ede8ff;
}

.legend-info-popover {
  .legend-info {
    width: 100%;
    height: 100%;
    max-width: 580px;

    .content {
      display: flex;
      text-align: right;
      justify-content: center;
      flex-direction: column;

      background: #fff;

      border-radius: 2px;
    }

    .title {
      font-size: 12px;
      line-height: 24px;
      text-align: right;
      color: #000;
    }

    .item {
      font-size: 10px;
      line-height: 22px;
      color: #5e5e77;
    }
  }
}

// ==============================|| END:CARD ||============================== //

.custom-search {
  .ant-input-affix-wrapper {
    border-radius: 16px !important;
    background: #f9fafb;
  }
}

.underlined-button {
  > button {
    background-color: transparent !important;

    border: none;
    display: block;
    font-size: 0.9em;
    font-weight: 500;
    outline: none;
    position: relative;
    transition: 0.3s;
    padding: 6px;
    max-height: 46px !important;
    color: $secondary-color !important;
    cursor: pointer;
    top: 4px;

    &::before {
      content: '';
      border-bottom: 2px solid $black;
      padding: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      transition: 0.3s;
    }
  }

  > button.active {
    color: $black !important;

    &::before {
      content: '';
      border-bottom: 2px solid $black;
      padding: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 80%;
      transition: 0.3s;
    }
  }
}

.global-filter-root {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  min-height: 50px;
  margin-left: -16px;

  > div {
    padding-right: 16px;
    padding-left: 16px;
    border-right: 2px solid #c9c1c1;
  }

  .MuiAutocomplete-inputRoot {
    padding: 0;
    padding-left: 5px;
    padding-right: 35px;

    > div {
      margin-right: 5px;
    }
  }
}

.single-auto-complate {
  .MuiAutocomplete-root {
    position: relative;

    .MuiInputBase-root {
      top: 0 !important;
    }

    .MuiChip-root {
      display: block;
      display: block;
      border: 0;
      font-weight: 600;
      top: 5px;

      svg {
        display: none;
      }
    }

    .Mui-focused {
      .MuiChip-root {
        font-size: 8px;
        line-height: 10px;
        top: 0;
        position: absolute;
      }
    }
  }
}

.ant-input-search .ant-input-lg {
  line-height: 1.558em !important;
}

.power-blocker {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
  color: #fff;
  font-size: 30px;
  line-height: 30px;
  text-align: center;

  &.page-blocker {
    position: fixed;
    left: 48px;
  }

  .blocker-content {
    width: 50%;
    min-width: 300px;
    max-width: 600px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 50%;
    margin-top: -100px;
    height: 200px;
  }

  &.blocker-closed {
    display: none;
  }

  &.blocker-error {
    //TODO: hata mesajlarını göster
    display: none;
  }
}

.ant-layout-sider-collapsed {
  .page-blocker {
    left: 70px;
  }
}

.block-loader {
  display: block;
  position: relative;
  height: 20px;
  width: 140px;
  margin: auto;
  margin-bottom: 30px;
  background-image: linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 20px auto;
  background-position:
    0 0,
    40px 0,
    80px 0,
    120px 0;
  animation: pgfill 1s linear infinite;
}

.very-small-table.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td,
.very-small-table.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > td {
  padding: 3px 8px;
}

@keyframes pgfill {
  0% {
    background-image: linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0);
  }

  25% {
    background-image: linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0);
  }

  50% {
    background-image: linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#fff 20px, transparent 0), linear-gradient(#fff 20px, transparent 0);
  }

  75% {
    background-image: linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#fff 20px, transparent 0);
  }

  100% {
    background-image: linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#bb2552 20px, transparent 0), linear-gradient(#bb2552 20px, transparent 0);
  }
}

@media screen and (max-width: 767px) {
  .page-blocker {
    left: 0 !important;
  }
}

.card-sm {
  padding: 16px;
  padding-top: 8px;

  .card-header {
    h3 {
      left: -16px;
      line-height: 32px;
    }
  }
}

//==========geçici düzenlemeler============//
.MuiBackdrop-root {
  .backdrop-inner {
    color: #fff;

    p {
      font-size: 1.6em;
      line-height: 1em;
      padding-top: 30px;
      text-align: center;
    }
  }

  .backdrop-icon-area {
    svg {
      width: 120px;
    }

    .anticon {
      font-size: 5em;
    }
  }

  &.backdrop-noresults,
  &.backdrop-error {
    background-color: #f4f2f2;

    .backdrop-inner {
      color: #333;
    }
  }
}

.custom-popup {
  position: relative;
  z-index: 3;
  width: 100%;
  min-height: 200px;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .mapboxgl-popup-close-button {
    font-size: 30px;
    /* height             : 0.65rem; */
    color: #948484;
    /* background-color   : white; */
    /* font-size          : 30px;
      height                : 27px;
      display               : flex;
      flex-direction        : column;
      align-items           : center;
      color                 : #e2dcdc;
      background-color      : #0f0f0f; */
  }

  .mapboxgl-popup-close-button:hover {
    /* font-size          : 30px;
      height             : 0.65rem; */
    /* color           : #e2dcdc;
      background-color   : #383434; */
    /* background-color   : #948484; */
    color: black;
    background-color: white;
  }

  .mapboxgl-popup-content {
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 0px 0px 6px 0px #00000029;
    /* word-wrap  : break-word;
      white-space   : pre-line;
      border        : 2px solid #C9C1C1; */
  }

  .map-popup-inner {
    width: 280px;
    position: relative;

    .chart {
      height: 200px !important;
      font-size: 0.9em;
    }
  }

  .map-popup-inner-sm {
    @extend .map-popup-inner;
    width: 180px;

    .map-popup-scroller {
      min-height: 50px;
    }
  }

  .map-popup-inner-xl {
    width: 580px;
    min-height: 150px;
    overflow: hidden;
  }

  .map-popup-inner-l {
    width: 350px;
    min-height: 80px;
  }

  .map-popup-buttons {
    padding: 0 5px;

    > .ant-btn {
      margin-top: 10px;
      margin-right: 10px;
    }
  }

  .map-popup-scroller {
    width: 100%;
    padding: 0 5px;
    min-height: 120px;
    max-height: 370px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0f0f0f;
    }
  }

  .map-popup-title {
    font-size: 14px;
    font-weight: 500;
    padding-right: 30px;
    margin-bottom: 7px;
  }

  .map-popup-row-base {
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 0 2px;
  }

  .map-popup-row {
    @extend .map-popup-row-base;

    &:nth-child(odd) {
      background-color: #f5f5f5;
    }

    .map-popup-row-label {
      font-size: 11px;
      font-weight: 500;
      display: table-cell;
      width: 42%;

      span{
        font-size: 13px;
        font-weight: 500;
      }

      &::after {
        content: ':';
        float: right;
      }
    }

    .map-popup-row-label-inner {
      font-size: 11px;
      font-weight: 500;
      display: table-cell;
      width: 50%;

      float: right;

      &::after {
        content: ':';
        float: right;
      }
    }

    .map-popup-row-value {
      display: table-cell;
      width: 65%;
      padding-left: 5px;
    }
  }
}

.mapboxgl-popup{
  width: 320px!important;
  max-width: 320px!important;
  z-index: 99;
  max-height: 90%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}