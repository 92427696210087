.table {
  width: 100%;
  border-collapse: collapse;
  min-height: 450px;
  height: 90%;
  font-size: 9px;
  font-weight: 700;

  .hour{
    width: 50px;
  }

  th,
  td {
    border: 1px solid #fff;
    border-radius: 2px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
}
