.container {
  display: flex;
  flex-direction: column;

  :global(.ant-select) {
    :global(.ant-select-selector) {
      border-radius: 20px;
    }
  }

  .bigValue {
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .header1 {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .unit {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem; /* 114.286% */
    }
  }
  .capacity {
    color: var(--primary-green-42-b-389, #42b389);
  }

  .voltage {
    color: var(--text-dark-grey-665-b-5-b, #665b5b);
  }

  .substationSelect {
    /* width: calc(100% - 32px); */
    width: 100%;
    padding: 10px 0px 12px 0px;
  }
  .substationInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0px 12px;
    border-bottom: 0.125rem dashed #c9c1c1;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px 0px 24px;

      .identifier {
        color: var(--power-dev-black-040000, #040000);
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 4px;
      }

      .name {
        color: var(--power-dev-black-040000, #040000);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .substationArea {
        color: var(--power-dev-black-040000, #040000);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 3px;
      }

      .substationAreaValue {
        color: var(--power-dev-black-040000, #040000);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-right: 4px;

      .hr {
        width: 1.5rem;
        height: 2px;
        margin: 10px;
        background: #c9c1c1;
        margin-right: 24px;
      }
    }
  }

  .capacities {
    padding: 12px 0px;
    border-bottom: 0.125rem solid #c9c1c1;

    .header {
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 0.5rem;
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .left {
        width: 45%;
      }

      .right {
        width: 45%;
      }

      .row{
        width: 100%;
      }

      .iconHeader {
        .icon {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 6px;
        }
        color: var(--power-dev-black-040000, #040000);
        text-align: center;
        font-size: 0.9rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-items: center;
        display: flex;
      }

      .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 28px 12px;

        .group {
          .header2 {
            color: var(--power-dev-black-040000, #040000);
            font-size: 0.85rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 6px 0px 2px 0px;
          }
          .value {
            color: var(--power-dev-black-040000, #040000);
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .unit2 {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }

  .branchInfo {
    padding: 12px 0px;
    border-bottom: 0.125rem dashed #c9c1c1;

    .branch {
      color: var(--power-dev-black-040000, #040000);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: space-between;

      .iconBranch {
        height: 8px;
        margin-right: 10px;
      }
    }

    .from {
      color: var(--power-dev-black-040000, #040000);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 2px 0;
    }

    .id {
      color: var(--power-dev-black-040000, #040000);
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .sections {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;
    }
  }

  .nodeSelect {
    padding: 12px 4px;
  }

  .nodeInfo {
    padding: 12px 0px;
  }
}
