.table {
  margin-top: 16px;
  margin-left: 16px;
  background-color: white;
  width: fit-content;
  height: fit-content;
  padding: 14px 24px 20px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 6px 0px #00000029;
  font-family: Inter;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  table {
    border-spacing: unset;

    thead tr th {
      color: #807272;
      text-align: right;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.125rem; /* 150% */
      text-align: end;
      padding-right: 8px;
      padding-bottom: 4px;
      border-bottom: 1px solid #C9C1C1;
    }

    thead {
      .firstHeader {
        color: #665b5b;
        font-size: 0.875rem;
        font-weight: 600;
        text-align: start;
      }
    }

    /** all rows, first td style */
    tbody {
      tr {
        td:first-child {
          font-size: 0.875rem;
          font-weight: 600;
          padding-left: 0;
        }
      }
    }

    /** first row, first td style */
    tbody tr:first-child td:first-child {
      color: #5d7092;
    }

    /** second row, first td style */
    tbody tr:nth-child(2) td:first-child {
      color: #42b389;
    }

    /** third row, first td style */
    tbody tr:nth-child(3) td:first-child {
      color: #ff7a45;
    }

    /** fourth row, first td style */
    tbody tr:nth-child(4) td:first-child {
      color: #597ef7;
    }

    .keys {
      color: #665b5b;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.125rem; /* 150% */
    }

    .values {
      color: #000;
      text-align: right;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1rem; /* 133.333% */
      min-width: 76px;
    }

    td {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 8px;
      padding-left: 8px;
      border-bottom: 1px solid #C9C1C1;
      border-right: 1px solid #C9C1C1;
    }

    /** last td style */
    td:last-child {
      border-right: none;
    }
  }
}
