.descContainer {
  position: relative;
  height: 200px;
  border-top: 1px solid rgb(201, 193, 193);

  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .scroll {
    height: 100%;
    padding: 8px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    .desc {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      color: #000;
      margin-bottom: 10px;

      .title {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        color: #000;
      }
    }
  }
}
