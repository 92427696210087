.control {
  position: fixed;
  right: 48px;
  top: 128px;
  bottom: 0px;
  width: 54px;

  .container {
    position: relative;
    width: 50%;
    height: calc(100% - 64px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 64px 0px; */
    border: 1px solid gray;

    background: linear-gradient(
          to bottom,
          #0c0e22 0%,
          #31183b 12.5%,
          #661f54 25%,
          #9f195b 37.5%,
          #d5234a 50%,
          #f05c42 62.5%,
          #f59971 75%,
          #f7d0b4 87.5%,
          #f9ecdd 100%,
        );
    

    .tick {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      /* background-color: gray; */
      color: black;
      padding-left: 115%;
      margin-top: -50%;
      font-size: 12px;
    }

    .input {
      
    }
  }
}
