.capacityControl {
  /* max-height: 210px; */

  .eye {
    background: #fff !important;
    border: none;
    color: #42b389 !important;
    padding: 0 8px;
  }

  .legendControl {
    padding: 4px 10px;
    box-shadow: 0px 0px 6px 0px #00000029;
    border-radius: 12px;
    margin-bottom: 10px;
  }
}

.nodeImg {
  width: 14px;
  height: 14px;
  background-color: #a4a1a1; /* İstediğin renk */
  -webkit-mask-image: url('https://image.power.dev/portal/map-assets/price/node.png'); /* SDF resminin yolu */
  -webkit-mask-size: cover;
  mask-image: url('https://image.power.dev/portal/map-assets/price/node.png'); /* SDF resminin yolu */
  mask-size: cover;
}
