.bottomButtons {
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0; */
  /* height: 80px;
  background-color: white; */
  box-shadow: 0px -10px 20px 0px rgba(255, 255, 255, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    width: 100%;
    padding: 14px 24px 0px;
  }
}
