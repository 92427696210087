.collapse {
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  transition: right 0.2s ease-in-out;
  z-index: 100;

  .closeBtn {
  }
}