.transmissionLinesLayerControlContainer {
  h3 {
    margin: 0 0 8px 0;
  }

  .body {
    height: fit-content;
    overflow: scroll;
    padding-top: 3px;
  }

  .iconText {
    display: flex;
    align-items: center;
    margin-left: -6px;
    /* padding-top: 2px; */

    img {
      height: 18px;
      margin-right: 2px;
    }
  }
}
