@import '_themes-vars.module.scss';

.u-full-width {
  width: 100% !important;
  height: 220px;
}

.card-panel {
  position: relative;
  padding: 0 32px 24px 32px;

  .card-panel-header {
    padding: 16px 32px;
    position: relative;
    height: 72px;

    .card-panel-title {
      position: absolute;
      top: 6px;
      left: -32px;
      padding-left: 12px;
      padding-right: 16px;

      color: $black;
      font-size: 16px;
      font-weight: 600;
      line-height: 44px;
      margin: 0;
      border-bottom: 2px $black solid;
    }

    .card-panel-toolbar {
      position: absolute;
      top: 16px;
      right: 0;

      .cp-toolbar-item {
        display: inline-block;
        margin-left: 10px;
        width: 25px;
        height: 25px;
        font-size: 21px;
        /* background-color: $cardBg; */
        color: #8d8c9c;
        fill: #8d8c9c;
        cursor: pointer;
      }
    }
  }

  .card-panel-content {
    position: relative;
    height: 100%;
    min-height: 400px;
    width: 100%;
    background-color: inherit;
  }

  // TODO: at full screen its overheight
  .card-panel-blocker {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.8);
    z-index: 4;

    .ant-skeleton {
      width: 100%;
      height: 100%;

      .ant-skeleton-image {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .panel-loader {
      margin: auto;
      top: 50%;
      margin-top: -70px;
    }

    .card-panel-blocker-message {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }

  .card-panel-error {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: 4;

    .cp-error-icon {
      position: relative;
      margin: auto;
      top: 50%;
      margin-top: -60px;
      font-size: 90px;
      color: $error500;
    }

    .card-panel-blocker-message {
      position: absolute;
      top: 50%;
      margin-top: 60px;
      width: 100%;
      text-align: center;
      color: $black;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 2px;
    }

    .cp-refresh-button {
      display: block;
      text-align: center;
      padding-top: 15px;
    }
  }

  .card-panel-no-data {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: 4;

    .cp-no-data-icon {
      position: relative;
      margin: auto;
      top: 50%;
      margin-top: -85px;
      font-size: 90px;
    }

    .card-panel-blocker-message {
      position: absolute;
      top: 50%;
      margin-top: 85px;
      width: 100%;
      text-align: center;
      color: $black;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 2px;
    }

    .cp-refresh-button {
      display: block;
      text-align: center;
      padding-top: 15px;
    }
  }

  .card-panel-filter-bar {
    position: relative;
    width: 100%;
    text-align: right;
    z-index: 5;
    padding-bottom: 15px;

    > * {
      margin-left: 15px;
    }
  }

  .card-panel-blocker + .card-panel-filter-bar {
    background-color: $cardBg;
  }
}

.card-panel.card-panel-default {
  /* background-color: $cardBg; */
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px #00000029;
}

.card-panel.card-panel-green {
  background-color: $green-base;

  .card-panel-blocker + .card-panel-filter-bar {
    background-color: $green-base;
  }
}

.cp-info-tooltip {
  position: relative;
  align-items: center;

  .cp-info-tooltip-title {
    height: 27px;
    background-color: $black;
    color: #fff;
    padding: 5px;
    padding-left: 15px;
  }

  .cp-info-tooltip-close {
    display: inline;
    position: absolute;
    float: right;
    right: 10px;

    font-size: 21px;
    top: 0;
    text-align: center;
  }

  .cp-info-tooltip-content {
    padding: 5px;
    padding-left: 15px;
  }
}

.card-panel.card-panel-sm {
  .card-panel-content {
    min-height: 340px;
    max-height: 340px;
  }
}

/* .card-panel.fullscreen-enabled {
    .card-panel-content {
        height: calc(100% - 70px);
    }
} */

.custom-table {
  .ant-table-body {
    height: 350px;
  }

  .ant-table-wrapper .ant-table {
    background-color: transparent;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: transparent;
    // border:1px solid ${grayBase.main};
    border: none;
    padding: 8px;
    line-height: 14px;
  }

  .ant-table-thead {
    background-color: #f4f2f2;
  }

  .ant-table-row:nth-child(odd) {
    background-color: #fff;
  }

  .ant-table-row:nth-child(even) {
    background-color: #eff3f0;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border: 1px solid #449c8c;
    padding: 6px 8px;
  }

  .ant-table-row:first-child > td {
    border-top-width: 2px !important;
  }

  .ant-table-row:last-child > td {
    border-bottom-width: 2px !important;
  }
}
