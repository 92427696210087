.header {
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  /* padding: 10px 0px; */
  display: flex;
  justify-content: space-between;
  background-color: #F0FFF7;
  /* border: 1px solid #fff; */
  box-shadow: 0px 0px 6px 0px #00000029;
  height: 32px;

  .left {
    display: flex;
    align-items: center;
    cursor: pointer;

    .openButton {
      display: flex;
      height: 32px;
      color: #42b389;
      padding: 0px 8px;
      

      .eye {
        width: 32px;
        height: 32px;
        padding: 2px 0 0 0;
        border: none;
        color: #42b389;
  
        :hover {
          color: #42b389;
          border-color: #65bf9b;
        }
      }
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #42B389;
      font-family: 'Inter', sans-serif;

    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
    }

    .collapse {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #42b389;
      padding: 0px 10px;
    }
  }
}

.container {
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  &.isOpen {
    height: auto;
    padding: 0px 10px;
  }

  &.isClose {
    height: 0;
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}
