.mapOverlay {
  width: 100%;
  .inner {
    background-color: #fff;

    .legend {
      .bar {
        height: 12px;
        width: 100%;
        background: linear-gradient(
          to right,
          #033028 0%,
          #093b32 1.41%,
          #10463c 2.82%,
          #185247 4.23%,
          #1f5d52 5.64%,
          #26695d 7.05%,
          #2d7668 8.46%,
          #358274 9.87%,
          #3c8f80 11.28%,
          #449c8c 12.69%,
          #4ca292 14.1%,
          #54a999 15.51%,
          #5bafa0 16.92%,
          #63b6a6 18.33%,
          #6abcad 19.74%,
          #72c3b4 21.15%,
          #79c9ba 22.56%,
          #81d0c1 23.97%,
          #88d6c8 25.38%,
          #90ddcf 26.79%,
          #91d9c4 28.2%,
          #94d4b9 29.61%,
          #97d0ae 31.02%,
          #9bcba3 32.43%,
          #9fc699 33.84%,
          #a4c08f 35.25%,
          #a8bb87 36.66%,
          #adb57f 38.07%,
          #b1af78 39.48%,
          #b5a973 40.89%,
          #b4a56c 42.3%,
          #b2a166 43.71%,
          #b19d60 45.12%,
          #b09959 46.53%,
          #af9553 47.94%,
          #ae904d 49.35%,
          #ad8c47 50.76%,
          #ac8841 52.17%,
          #ab833b 53.58%,
          #aa7f35 54.99%,
          #ae7c33 56.4%,
          #b17931 57.81%,
          #b57630 59.22%,
          #b9732f 60.63%,
          #bd702f 62.04%,
          #c16c2f 63.45%,
          #c46930 64.86%,
          #c86532 66.27%,
          #cc6034 67.68%,
          #cf5c36 69.09%,
          #ce5639 70.5%,
          #cd513b 71.91%,
          #cc4b3e 73.32%,
          #ca4641 74.73%,
          #c84043 76.14%,
          #c63b46 77.55%,
          #c43549 78.96%,
          #c1304c 80.37%,
          #be2a4f 81.78%,
          #bb2552 83.19%,
          #b4224a 84.6%,
          #ac2042 86.01%,
          #a51d3b 87.42%,
          #9d1b33 88.83%,
          #96192c 90.24%,
          #8e1826 91.65%,
          #86161f 93.06%,
          #7e1519 94.47%,
          #771313 95.88%,
          #6f120d 100% /* 97.29%, */
        );
      }

      .legendItems {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 2px 0px;
        font-size: 12px;
        color: #666;

        .legendItem {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          line-height: 15px;
        }
      }
    }
  }
}
