$fontFamily:
  Inter,
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol';

// paper & background
$baseBg: #f4f2f2;
$baseBorderColor: #c9c1c1;
$paper: #ffffff;
$white: hwb(0 100% 0%);
$cardBg: #f4f2f2;
$cardBgGreen: #ecf5f3;
$black: #040000;
$linghtTextColor: #665b5b;

$primary-color: #538fbd; //nerden gelior?
$secondary-color: #5e5e77; //nerden gelior?

// primary
$primary50: #e1ebf0;
$primary100: #d1dde3;
$primary200: #a3c3d6;
$primary300: #79a9c9;
$primary400: #538fbd;
$primary500: #3174af;
$primary600: #20558a;
$primary700: #1e476b;
$primary800: #102a45;

// secondary
$secondary50: #f9edfc;
$secondary100: #dccae3;
$secondary200: #c59cd6;
$secondary300: #b585d1;
$secondary400: #ad73c9;
$secondary500: #934ebc;
$secondary600: #703696;
$secondary700: #4f2370;
$secondary800: #30134a;

// success Colors
$success-base: #42b389;
$success50: #f0fffa;
$success100: #dfede9;
$success200: #a9d4ca;
$success300: #7fc7ba;
$success400: #65a89a;
$success500: #42b389;
$success600: #327166;
$success700: #1b4f49;
$success800: #0c2926;

// error
$error-base: #d9796c;
$error50: #fff4f0;
$error100: #fff3f0;
$error200: #f2cfc7;
$error300: #e6a397;
$error400: #d9796c;
$error500: #cc5145;
$error600: #bf2a22;
$error700: #991614;
$error800: #73090b;

// warning
$warning-base: #df9968;
$warning50: #fff5f0;
$warning100: #fff9f0;
$warning200: #f7ddc3;
$warning300: #ebbb94;
$warning400: #df9968;
$warning500: #b8764d;
$warning600: #b15f25;
$warning700: #764019;
$warning800: #6b3922;

// orange
$orange50: #fff6f0;
$orange100: #ffefe6;
$orange200: #f5cbb5;
$orange300: #e8a587;
$orange400: #db805c;
$orange500: #cf5c36;
$orange600: #a84023;
$orange700: #822915;
$orange800: #601909;

// green
$green-base: #5ace88;
$green50: #ecfff0;
$green100: #d5fadd;
$green200: #acebc1;
$green300: #83dda5;
$green400: #5ace88;
$green500: #31bf6c;
$green600: #00994a;
$green700: #007429;
$green800: #005107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey350: #c4c4c4;
$grey400: #b8b8b8;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #636363;
$grey800: #565656;
$grey900: #212121;

$lightBlackText: #948484;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #040000; // level 3
$darkPaper: #333030; // level 4

// dark 800 & 900
$darkLevel1: #4c4848; // level 1
$darkLevel2: #615e5e; // level 2

// primary dark
$darkPrimary50: #e1ebf0;
$darkPrimary100: #c8dae7;
$darkPrimary200: #afc9dd;
$darkPrimary300: #96b8d4;
$darkPrimary400: #7ca7cb;
$darkPrimary500: #6396c2;
$darkPrimary600: #4a85b8;
$darkPrimary700: #3174af;
$darkPrimary800: #20558a;

// secondary dark
$darkSecondary50: #f9edfc;
$darkSecondary100: #dccae3;
$darkSecondary200: #c59cd6;
$darkSecondary300: #b585d1;
$darkSecondary400: #b47fcf;
$darkSecondary500: #a471c2;
$darkSecondary600: #935eb4;
$darkSecondary700: #814aa5;
$darkSecondary800: #703696;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

//TODO
$fontSizeHeading4: 20px;

//TODO: aralıkları iyi belirlemek gerekiyor.
$breakpointXs: 480px;
$breakpointSm: 768px;
$breakpointMd: 1024px;
$breakpointLg: 1266px;
$breakpointXl: 1536px;
$breakpoinXxl: 1920px;

//prosidebar
$breakpoint-xs: $breakpointXs;
$breakpoint-sm: $breakpointSm;
$breakpoint-md: $breakpointMd;
$breakpoint-lg: $breakpointLg;
$breakpoint-xl: $breakpointXl;
$breakpoint-xxl: $breakpoinXxl;
