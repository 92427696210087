.staticBaseLayerControlContainer {
  /* box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  padding: 10px 10px 10px;

  min-width: 223px;
  max-width: 450px;
  max-height: 55vh;

  transform: scaleY(1);
  transform-origin: top;
  transition: transform 4s;

  display: flex;
  flex-direction: column;
  overflow: hidden; */

  h3 {
    margin: 0 0 8px 0;
  }

  .body {
    height: fit-content;
    overflow: scroll;
    padding-top: 3px;
  }

  .iconText {
    display: flex;
    align-items: center;
    /* margin-left: -6px; */
    /* padding-top: 2px; */

    img {
      /* height: 18px; */
      margin-right: 6px;
    }
  }
}
