@import '_themes-vars.module.scss';
.ant-layout {
  .ant-layout-sider-light {
    .ant-layout-sider-trigger {
      background-color: $success300;
      color: white;
      &:hover {
        background-color: $success-base;
      }
    }
  }
}
.layout-menu-sider {
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  /* background-color: $baseBg !important; */
  flex: none;
  top: 0;
  bottom: 0;
  left: 0;
  border-right: 1px solid rgb(201, 193, 193);
  z-index: 999;
}
.ant-layout-sider-children {
  position: relative;
}
.sider-menu-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
  bottom: 48px;
  overflow-y: auto;

  .ant-menu-light {
    background-color: transparent;
  }
}
.user-dropdown-menu {
  display: none;
  min-height: 96px;
  font-size: 16px;
  line-height: 32px;
  width: 280px;
  background-color: $black;
  color: #fff;
  padding: 16px;
  position: absolute;
  right: 46px;
  top: 0;
  border-bottom-left-radius: 6px;
  > * {
    display: block;
    color: #fff;
  }
  .user-dd-name {
    font-size: 18px;
    font-weight: 500;
  }
}
.layout-sidebar-right {
  width: 48px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  border-left: 1px solid $baseBorderColor;
  z-index: 1001;
}
.layout-sidebar-usermenu {
  display: block;
  height: 48px;
  .user-avatar {
    display: block;
    padding: 6px;
    .user-avatar-icon {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      border: 2px solid $lightBlackText;
      text-align: center;
      font-size: 13px;
      display: block;
      text-align: center;
      line-height: 32px;
      color: $black;
      cursor: pointer;
    }
  }
  &:hover {
    .user-avatar {
      background-color: $black;
      .user-avatar-icon {
        border-color: #fff;
        color: #fff;
      }
    }
    .user-dropdown-menu {
      display: block;
    }
  }
}
.layout-sidebar-menu-item {
  display: block;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  color: $lightBlackText;
  > a,
  > span {
    display: block;
    text-align: center;
    color: inherit;
    cursor: pointer;
    position: relative;
    > .badge {
      position: absolute;
      bottom: 5px;
      right: 5px;
      border-radius: 50%;
      min-width: 16px;
      height: 16px;
      color: #fff;
      font-size: 9px;
      background-color: $error500;
      line-height: 14px;
      font-weight: 500;
      border: 1px solid #fff;
    }
  }
  &:hover {
    color: $black;
    background-color: $baseBorderColor;
  }
}

.header-action-buttons {
  display: inline-block;
  font-size: 20px;
  line-height: 48px;
  > * {
    margin-left: 0;
    width: 48px;
    display: inline-block;
    cursor: pointer;
  }
}

.ant-menu-inline.ant-menu-root {
  .ant-menu-item {
    &.sidebar-menu-title {
      display: none;
    }
  }
}
.ant-menu-submenu {
  .sidebar-menu-title {
    > span {
      font-weight: bold;
      display: block;
      margin-left: -10px;
    }
  }
}
.ant-menu-submenu-popup.ant-menu-submenu {
  min-width: 220px;
  padding-left: 10px;
}
