@import '_themes-vars.module.scss';
.info-box-container {
  width: 100%;
  display: block;
}

.info-box {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 8px;
  /* min-height: 100px; */
  -webkit-box-shadow: 0px 0px 6px 0px #00000029;
  -moz-box-shadow: 0px 0px 6px 0px #00000029;
  box-shadow: 0px 0px 6px 0px #00000029;
  /* min-width: 280px; */
  padding: 10px 12px;
  overflow: hidden;
}
.info-box-headline {
  font-weight: 600;
  color: $success500;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 12px;
}
.info-box-label-value {
  text-align: right;
  display: block;
  line-height: 20px;
}
.info-box-label {
  font-weight: 300;
  color: $linghtTextColor;
  display: inline-block;
  padding-right: 10px;
}

.info-box-value {
  font-weight: 600;
  color: $linghtTextColor;
  display: inline-block;
}

@media screen and (min-width: 1252px) {
  .info-box-container {
    width: 1252px;
    margin-left: auto;
    margin-right: auto;
  }
}
