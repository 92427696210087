.collapse {
  position: fixed;
  top: -10px;
  right: 400px;
  cursor: pointer;
  transition: right 0.2s ease-in-out;

  .closeBtn {
  }
}

.siderControlContainer {
  box-shadow: 0px 0px 6px 0px #00000029;
  background: #fff;
  transition: width 0.2s ease-in-out;
  margin-top: -10px;
  margin-right: -10px;
  // prevent-select
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &.isOpen {
    width: 400px;
  }

  &.isClose {
    width: 0;
    box-shadow: none;
    overflow: hidden;
  }

  .content {
    height: 100%;
    max-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
