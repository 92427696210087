@import '../../../styles/themes-vars.module.scss';
.headerPrimary {
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 1;
  position: sticky;
  display: flex;
  align-items: center;

  top: 0;
  border-bottom: 1px solid $baseBorderColor;
  background-color: white;
  height: 48px;

  z-index: 1000;

  .logoVersion {
    position: absolute;
    right: 110px;
    font-size: 10px;
    letter-spacing: 2px;
    line-height: 10px;
    top: 0;
    height: 48px;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 20px;
      margin-right: 10px;
    }

    .label {
      font-size: 16px;
      line-height: 16px;
      padding-right: 6px;
      font-weight: 600;
      color: #040000;
    }
    .zenobe {
      color: #f86904;
      padding-top: 3px;
    }
    .cve {
      color: #29235c;
    }

    .powerdev {
      color: #040000;
    }
  }
}
