@import '../../../../styles/_themes-vars.module.scss';
.header {
  width: 100%;
  padding-left: 64px;
  display: flex;
  justify-content: space-between;

  .headerLogo {
    position: absolute;
    left: -48px;
    top: 0;
    width: 96px;
    height: 48px;
    img {
      height: 100%;
    }
  }

  .headerTitle {
    font-weight: 600;
    font-size: 1.4em;
    line-height: 48px;
  }

  .headerUser {
    color: #948484;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.5em;
  }
}
