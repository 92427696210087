.content {
  width: 100%;
  min-height: calc(100svh - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('./../../../assets/images/login-bg-tiny.jpeg');
  background-position-x: 50%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  */

  .form {
    width: 350px;
    min-height: 350px;
    padding: 2rem 2rem 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #80808080;

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 2.5rem 0;
      text-align: center;
    }

    .subtitle {
      /* font-size  : 1rem;
      font-weight: 400; */
      margin: 0 0 1.5rem;
      text-align: center;
    }
  }
}
