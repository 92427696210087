.title {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #635c5c;
  padding: 20px 0px 0px 16px;
  text-align: center;
}

.tabs {
   width: 100%;
  padding: 0 10px;
  margin: 12px 0px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  justify-content: space-around;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .tab {
    min-height: 200px;
    min-width: 500px;
    width: 500px;
    cursor: pointer;
    padding: 8px;
    border: 1px solid #ccc;
    margin: 4px;

    .substationInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 4px 0px 12px;
      border-bottom: 0.125rem dashed #c9c1c1;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .identifier {
          color: var(--power-dev-black-040000, #040000);
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-bottom: 4px;
        }

        .name {
          color: var(--power-dev-black-040000, #040000);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .substationArea {
          color: var(--power-dev-black-040000, #040000);
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-bottom: 3px;
        }

        .substationAreaValue {
          color: var(--power-dev-black-040000, #040000);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-right: 4px;
      }
    }

    .capacities {
      padding: 12px 0px 0px;

      .header {
        color: #000;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-bottom: 0.5rem;
      }

      .body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .left {
          width: 33%;
        }

        .right {
          width: 33%;
        }

        .iconHeader {
          .icon {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 6px;
          }
          color: var(--power-dev-black-040000, #040000);
          text-align: center;
          font-size: 0.9rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          align-items: center;
          display: flex;
        }

        .content {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          .group {
            .header2 {
              color: var(--power-dev-black-040000, #040000);
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              padding: 8px 0px 2px 0px;
            }
            .value {
              color: var(--power-dev-black-040000, #040000);
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              .unit2 {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}
