.table {
  border: 1px solid #f5f5f5;
  font-size: 13px;
  /* table-layout   : fixed; */

  thead {
    th {
      background-color: #ffffff;
      font-size: 13px;
      text-align: left;
    }
  }

  tr:nth-child(odd) {
    background-color: #f5f5f5;
  }

  tr.highlight {
    background-color: #42b389 !important;
    color: white;
  }

  .key {
    max-width: 40%;
    font-size: 12px;
    font-weight: 400;

    &::after {
      content: ':';
      float: right;
      font-weight: normal;
      padding-left: 4px;
    }
  }

  .val {
    padding: 2px 10px 2px 4px;
    min-width: 60%;
  }
}
