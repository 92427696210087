.headerSecondary {
  padding: 0;
  margin: 0;
  width: 100%;
  z-index: 1;
  position: sticky;
  display: flex;
  align-items: center;

  top: 72px;
  border-bottom: 1px #e0e0e0 solid;
  background-color: white;

  height: auto;
}
