@import '_themes-vars.module.scss';
@import 'utils.scss';
@import 'layout.scss';
@import 'card-panel.scss';
@import 'filter-bar.scss';
@import 'page-container.scss';
@import 'dashboards.scss';
@import 'component-styles.scss';
@import 'info-box.scss';

* {
  box-sizing: content-box;
}

body,
html {
  -webkit-font-smoothing: unset;
  color: $black;
  overscroll-behavior: none;
}
