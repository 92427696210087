// color variants
@import '_themes-vars.module.scss';

.static-map-cont {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f4f2f2;
  background-position: 50% 50%;
  transition: width ease 10s;
}

.info-card-horizontal {
  // padding: 16px;
  // background-color: #f4f2f2;
  display: grid;
  grid-template-columns: 50% 50%;
  line-height: 1.15em;
  // border-radius: 4px;
  margin: 0;

  &:last-child {
    margin-bottom: 0;
  }

  > dt {
    padding-right: 5px;
    font-size: 0.85em;
  }

  > dd {
    padding-right: 5px;
    font-size: 0.92em;
    min-height: 0.92em;
  }
}

.info-card-number-box {
  position: relative;
  text-align: right;
  line-height: 2em;
  font-size: 1.9em;
  font-weight: 500;
  border-top: 2px solid #c9c1c1;
  padding-top: 12px;
  padding-left: 150px;

  > span {
    position: absolute;
    left: 0;
    top: 25px;
    vertical-align: middle;
    text-align: left;
    width: 150px;
    font-size: 13px;
    padding-left: 10px;
    line-height: 15px;
    color: $grey600;
  }

  > b {
    font-weight: 500;
    font-size: 0.7em;
  }
}

.info-card-bottom-line {
  .info-card-number-box {
    border-bottom: 2px solid #c9c1c1;
  }
}

.power-link-btn.margin {
  margin-bottom: 10px;
}

@media screen and (max-width: 1440px) {
  .info-card-number-box {
    position: relative;
    text-align: right;
    line-height: 2em;
    font-size: 1.4em;
    font-weight: 500;
    border-top: 2px solid #c9c1c1;
    padding-top: 12px;
    padding-left: 130px;

    > span {
      top: 25px;
      width: 130px;
      font-size: 11px;
      line-height: 13px;
    }
  }
}
