.fullContainer {
  width: 100%;
  height: calc(100svh - 48px);
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;

    :global(.mapboxgl-popup) {
        width: auto!important;
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 100;
    }
  }
}
