.capacityControl{
  max-height: 210px;

  .eye{
    background: #fff !important;
    border: none;
    color: #42b389 !important;
    padding: 0 8px;
  }

  .legendControl{
    padding: 4px 10px;
    box-shadow: 0px 0px 6px 0px #00000029;
    border-radius: 12px;
    margin-bottom: 10px;
  }
}