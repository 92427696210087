@import '../../styles/themes-vars.module';

.tabContainer {
  margin-top: 8px;

  :global(.ant-tabs) {
    :global(.ant-tabs-nav-wrap) {
      width: 100%;
      display: block;
      padding-left: 10px;
    }

    :global(.ant-tabs-tab) {
      margin-right: 10px;
      min-width: 280px;
      text-align: center;
      border-color: $baseBorderColor;
    }

    :global(.ant-tabs-nav) {
      margin-bottom: 0;

      &::before {
        border-bottom: 1px solid $baseBorderColor;
      }
    }
  }
}
