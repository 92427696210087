.searchControlContainer {
  padding: 0;/*  10px */;
  /* border-radius: 100px; */
  overflow: hidden;
  /* box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  background: #fff;
  
  padding: 10px;
  padding: 2px; */
}
