.fullContainer {
  /* position: absolute;
  top: 0;
  left: 0px; */
  width: 100%;
  /* height: calc(100svh - 48px); */
  margin: 0px 64px 8px 0px;

  display: flex;
  flex-direction: column;

  /* background-color: green; */

  .filterBar {
    border-bottom: 1px solid #c9c1c1;
    min-height: 64px;
    padding: 0 16px;
    /* background-color: orange; */
  }

  .container {
    flex: 1;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    position: relative;
    padding: 12px;

    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    :global(.mapboxgl-ctrl-top-right) {
      display: flex;
      flex-direction: row-reverse;
      height: 1px;
    }
  }
}

:global(.mapboxgl-ctrl-top-left) {
  z-index: 3;
  top: 8px;
  left: 8px;
  bottom: 8px;

  :global(.mapboxgl-ctrl) {
    margin: 5px 0;
    padding: 0;
    border-radius: 4px;
    max-height: calc(100% - 54px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
