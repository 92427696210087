.icon-H {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #31bf6c;
  border-radius: 3px;

  width: 18px;
  height: 18px;
  line-height: 18px;
  box-sizing: content-box;

  padding: 0;
  margin-left: 2px;
  margin-right: 8px;
}

.icon-N {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #31bf6c;
  border-radius: 2px;

  width: 16px;
  height: 16px;
  line-height: 16px;
  box-sizing: content-box;

  padding: 0;
  margin-left: 2px;
  margin-right: 8px;

  transform: rotate(45deg);

  .tag {
    display: block;
    transform: rotate(-45deg);
  }
}

.icon-B {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #31bf6c;
  border-radius: 50%;

  width: 18px;
  height: 18px;
  line-height: 18px;
  box-sizing: content-box;

  padding: 0;
  margin-left: 2px;
  margin-right: 8px;
}

.icon-S {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #e74c3c;
  border-radius: 3px;

  width: 18px;
  height: 18px;
  line-height: 18px;
  box-sizing: content-box;

  padding: 0;
  margin-left: 2px;
  margin-right: 8px;
}

.provider {
  display: inline-block;
  font-size: 0.9em;
  text-align: center;
  color: #fff;
  background-color: #757575;
  border-radius: 3px;

  height: 18px;
  line-height: 18px;
  box-sizing: content-box;

  padding: 0 4px;
  margin-left: 6px;
}
