.table {
  margin: 6px 6px 20px;
  background-color: white;
  /* width: fit-content; */
  height: fit-content;
  padding: 12px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 6px 0px #00000029;
  font-family: Inter;

  table {
    border-spacing: unset;
    width: 100%;

    thead tr th {
      color: #807272;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.125rem; /* 150% */
      /* padding-right: 8px; */
      padding-bottom: 4px;
      border-bottom: 1px solid #c9c1c1;
      border-right: 1px solid #c9c1c1;
    }

    thead tr th:last-child{
      border-right: none;
    }

    thead {
      .firstHeader {
        color: #665b5b;
        font-size: 0.875rem;
        font-weight: 600;
        text-align: start;
      }
    }

    /** all rows, first td style */
    tbody {
      tr {
        td:first-child {
          font-size: 12px;
          font-weight: 600;
          padding-left: 0;
          width: 55%;
          color: black;
        }
      }
    }

    /* tbody tr:first-child td:first-child {
      color: #5d7092;
    }
    tbody tr:nth-child(2) td:first-child {
      color: #42b389;
    }
    tbody tr:nth-child(3) td:first-child {
      color: #ff7a45;
    }
    tbody tr:nth-child(4) td:first-child {
      color: #597ef7;
    } */

    .keys {
      color: #665b5b;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.125rem; /* 150% */
      width: 36%;
    }

    .values {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1rem; /* 133.333% */
      /* min-width: 76px; */
    }

    td {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 8px;
      padding-left: 8px;
      border-bottom: 1px solid #c9c1c1;
      border-right: 1px solid #c9c1c1;
    }

    /** last td style */
    td:last-child {
      border-right: none;
    }
  }
}
