.siderControlContainer {
  position: relative;
  font-family: 'Inter';
  box-shadow: 0px 0px 6px 0px #00000029;
  background: #fff;
  /* border-radius: 4px; */
  padding: 2px 0;
  transition: all 0.2s ease-in-out;
  height: 100%;
  border-radius: 4px;

  &.isOpen {
    width: 24rem;
  }

  &.isClose {
    width: 0;
    box-shadow: none;
    overflow: hidden;
  }

  .header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    z-index: 1000;
    background: white;
    text-align: right;

    .closeBtn {
      button {
        border: none;
        box-shadow: none;
      }
    }
  }

  .content {
    position: relative;
    /* height: calc(100% - 24px); */
    height: 100%;
    padding: 0px 15px 15px 15px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
